import styles from './ExternalLink.module.scss';

export interface Props {
  href: string;
  text: string;
}

export const ExternalLink = ({ text, ...props }: Props) => (
  <a
    {...props}
    className={styles.externalLink}
    target="_blank"
    rel="noreferrer"
  >
    {text}
  </a>
);
