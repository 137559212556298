import { useApi } from '../../hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { PageLoading } from '../../components/layout/PageLoading';
import { Subscription } from '../../model/Subscription';
import { formatDate, today } from '../../utils/date';
import { Form, FormSection } from '../../components/form/Form';
import { ItemRows } from '../../components/ItemRows';
import { Checkbox } from '../../components/form/Checkbox';
import { ExternalLink } from '../../components/ExternalLink';
import { formatEuro } from '../../utils/currency';
import styles from './SubscriptionSettings.module.scss';
import { useAssertCurrent } from '../../hooks/useCurrent';

export const SubscriptionSettings = () => {
  const api = useApi();
  const [subscription, setSubscription] = useState<Subscription>();
  const [termsAgreed, setTermsAgreed] = useState(false);
  const { refresh: refreshCurrent } = useAssertCurrent();

  const refresh = useCallback(() => {
    api.subscription.getSubscription().then(setSubscription);
    refreshCurrent();
  }, [api.subscription, refreshCurrent]);

  useEffect(refresh, [refresh]);

  if (!subscription) {
    return <PageLoading />;
  }

  return (
    <div>
      <FormSection text="Status" />
      <ItemRows
        data={[
          // Only show the trial end date if there is no, and has not been a subscription.
          !subscription.subscriptionStart && {
            icon: 'calendar-xmark',
            key: 'Proefabonnement eindigt op',
            value: formatDate(subscription.trialEnd),
          },
          !!subscription.subscriptionStart && {
            icon: 'calendar-check',
            key: 'Abonnement gestart',
            value: formatDate(subscription.subscriptionStart),
          },
          !!subscription.subscriptionEnd && {
            icon: 'calendar-xmark',
            key: 'Abonnement eindigt op',
            value: formatDate(subscription.subscriptionEnd),
          },
          {
            icon: 'user-group',
            key: 'Actieve certificaathouders',
            value: subscription.participants,
          },
        ]}
      />
      <div className={styles.manage}>
        {subscription.subscribed ? (
          <Form
            onSubmit={() => api.subscription.stopSubscription().then(refresh)}
            submitLabel="Abonnement stopzetten"
          >
            <FormSection text="Abonnement stopzetten" />
            <div>
              Wanneer je je abonnement stopzet verlies je op{' '}
              {formatDate(subscription.renewDate ?? today())} toegang tot je
              data. Tot die tijd kan je ook eenvoudig je abonnement weer
              aanzetten.
            </div>
          </Form>
        ) : (
          <Form
            onSubmit={() => api.subscription.startSubscription().then(refresh)}
            submitLabel="Abonneren"
            submitDisabled={!termsAgreed}
          >
            <FormSection text="Abonneren" />
            <div className={styles.priceRows}>
              <div>
                Basispakket
                <br />
                (incl. 20 certificaathouders)
              </div>
              <div>{formatEuro(subscription.basePrice)}</div>
              <div>Extra certificaathouders</div>
              <div>
                {subscription.extraParticipants} x{' '}
                {formatEuro(subscription.pricePerExtraParticipant)}
              </div>
              <div className={styles.sumDivider}>+</div>
              <div className={styles.total}>Totaal per maand (excl. btw)</div>
              <div className={styles.total}>
                {formatEuro(subscription.totalPrice)}
              </div>
            </div>
            <div>
              Je betaalt maandelijks achteraf voor het maximum aantal actieve
              certificaathouders in je organisatie. Je kan je abonnement op elk
              moment stopzetten.
            </div>
            <Checkbox
              checked={termsAgreed}
              onChange={(c) => setTermsAgreed(c)}
              required
            >
              <span>
                Ik ga akkoord met de{' '}
                <ExternalLink
                  text="algemene voorwaarden"
                  href="https://www.participro.nl/voorwaarden"
                />
                .
              </span>
            </Checkbox>
          </Form>
        )}
      </div>
    </div>
  );
};
