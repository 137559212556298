import { differenceInDays } from 'date-fns';

export function today(): string {
  return new Date().toISOString().substring(0, 10);
}

export function formatDate(date: string): string {
  return Intl.DateTimeFormat('nl-NL', {
    dateStyle: 'long',
  }).format(new Date(date));
}

export function daysUntil(date: string): number {
  return differenceInDays(date, today());
}
