import { useState } from 'react';
import { Menu } from './Menu';
import { TopBar } from './TopBar';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAssertCurrent } from '../../hooks/useCurrent';
import { daysUntil } from '../../utils/date';
import { RoleValue } from '../../model/Role';

export const Layout = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { tenant, roles } = useAssertCurrent();
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we are in a trial period, or have an active subscription.
  const inTrialPeriod = daysUntil(tenant.trialEnd) >= 0;
  const activeSubscription =
    !!tenant.subscriptionStart &&
    (!tenant.subscriptionEnd || daysUntil(tenant.subscriptionEnd) >= 0);
  const redirectToSubscription = !inTrialPeriod && !activeSubscription;

  // Check if we should redirect to the subscription settings.
  if (
    redirectToSubscription &&
    location.pathname !== '/' &&
    location.pathname !== '/settings'
  ) {
    navigate('/settings#Abonnement');
  }

  return (
    <div className="layout">
      <Menu visible={showMenu} onClose={() => setShowMenu(false)} />
      <div className="main-wrapper">
        <TopBar
          showMenu={showMenu}
          onToggleMenu={() => setShowMenu((prevState) => !prevState)}
        />

        {(!tenant.subscriptionStart || tenant.subscriptionEnd) && (
          <div className="subscription-status">
            {redirectToSubscription ? (
              <>Je abonnement is afgelopen.</>
            ) : (
              <>
                {!tenant.subscriptionStart && (
                  <>
                    Je proefabonnement eindigt over {daysUntil(tenant.trialEnd)}{' '}
                    dagen.
                  </>
                )}
                {tenant.subscriptionEnd && (
                  <>
                    Je abonnement loopt over {daysUntil(tenant.subscriptionEnd)}{' '}
                    dagen af.
                  </>
                )}
              </>
            )}{' '}
            {roles.includes(RoleValue.admin) && (
              <Link to="/settings#Abonnement">Beheer hier je abonnement.</Link>
            )}
          </div>
        )}

        <Outlet />
      </div>
    </div>
  );
};
