import { ApiClient } from './ApiClient';
import { Subscription } from '../model/Subscription';
import { Injector } from 'react-service-injector';

export class SubscriptionService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
  }

  public getSubscription(): Promise<Subscription> {
    return this.api.jsonGet('/api/subscription');
  }

  public startSubscription(): Promise<void> {
    return this.api.jsonPost('/api/subscription', undefined);
  }

  public stopSubscription(): Promise<void> {
    return this.api.jsonDelete('/api/subscription');
  }
}
