import { Tabs } from '../../components/Tabs';
import { useAssertCurrent } from '../../hooks/useCurrent';
import { RoleValue } from '../../model/Role';
import { CompanySettings } from './CompanySettings';
import { UserSettings } from './UserSettings';
import { PasswordSettings } from './PasswordSettings';
import { ShareSettings } from './ShareSettings';
import { PageHeader } from '../../components/layout/PageHeader';
import { RoleSettings } from './RoleSettings';
import { SubscriptionSettings } from './SubscriptionSettings';

export const SettingsPage = () => {
  const { roles } = useAssertCurrent();

  return (
    <main className="settings-page">
      <PageHeader title="Instellingen" />
      <Tabs
        tabs={[
          {
            name: 'Gebruiker',
            element: <UserSettings />,
          },
          {
            name: 'Wachtwoord',
            element: <PasswordSettings />,
          },
          roles.includes(RoleValue.admin) && {
            name: 'Rollen',
            element: <RoleSettings />,
          },
          roles.includes(RoleValue.admin) && {
            name: 'Aandelen',
            element: <ShareSettings />,
          },
          roles.includes(RoleValue.admin) && {
            name: 'Bedrijf',
            element: <CompanySettings />,
          },
          roles.includes(RoleValue.admin) && {
            name: 'Abonnement',
            element: <SubscriptionSettings />,
          },
        ]}
      />
    </main>
  );
};
